import { render, staticRenderFns } from "./success.vue?vue&type=template&id=18ce7c61&scoped=true&lang=pug&"
import script from "./success.vue?vue&type=script&lang=ts&"
export * from "./success.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18ce7c61",
  null
  
)

export default component.exports